import React from "react"
import SortableTable from "./sortable-table"

export default function Supporters({ supporters }) {
  const columns = [
    {
      field: "City",
    },
    {
      field: "Person",
      header: "Elected Official",
      isSortable: false,
    },
  ]

  return (
    <>
      <SortableTable
        className="w-full xl:w-3/4 xl:mx-auto"
        rows={supporters}
        columns={columns}
        defaultSort="City:asc"
        caption={() => {
          return (
            <>
              <span className="block">
                * = Elected official voted FOR the CCLC Resolution their city
                passed (see Resolutions tab)
              </span>
              <span className="block">
                ** = Elected official signed on for support of CCLC's mission
                while in office, but is no longer in office
              </span>
            </>
          )
        }}
      />
    </>
  )
}
