import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SupportersTable from "../components/supporters-table"
import { getSupporters } from "../data/voting-record"
import dayjs from "dayjs"

export default function ElectedOfficials() {
  const supporters = getSupporters()

  return (
    <Layout>
      <SEO title="Elected Officials" />
      <h1>Elected Officials</h1>
      <p>
        California Cities for Local Control is undertaking the significant
        effort of reaching out to all the elected officials in all the cities in
        California. Below, you will see the growing list of Elected officials
        that have shared with us their support of our mission, as of the date on
        this top of the list. This list will be updated periodically, so check
        back often, as more supporters are being added almost daily!
      </p>

      <div className="mb-5">
        <p className="text-xl">
          As of {dayjs().format("M/D/YYYY")}, we have{" "}
          <span className="text-green-500 font-extrabold underline">
            {supporters.length}
          </span>{" "}
          California Elected Officials in support of our mission as listed
          below.
        </p>
      </div>

      <SupportersTable supporters={supporters} />
    </Layout>
  )
}
